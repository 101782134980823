import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Breadcrumbs } from '../components/Breadcrumbs';
import PostList from '../components/Posts/PostList';
import { decodeEntities } from '../utils/helpers';
import Heading from '../components/Heading/Heading';
import './blog.scss';

export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const { allWordpressPost, wordpressPage, categoryFilter, categories, wordpressWpSettings, siteSettings } = data;
    const { showAuthor } = siteSettings.options
    const { edges: posts } = allWordpressPost
    const featuredImage = categories.edges[0] && categories.edges[0].node.featured_image_url ? categories.edges[0].node.featured_image_url : ''
    const { title } = wordpressPage;
    const { title: siteTitle, blogSlug, siteUrl } = wordpressWpSettings;
    const canonicalAttr = `${siteUrl}/${blogSlug}/`;

    return (
      <Layout location={location}>
        <SEO 
          title={`Blog | ${decodeEntities(siteTitle)}`}
          canonical={canonicalAttr}
        />
        <div className="blog-head">
          <div className="wrap">
            <Breadcrumbs location={location} />
            <Heading tag="h1" className="page-title center">{decodeEntities(title)}</Heading>
          </div>
        </div>
        <PostList
          posts={posts}
          title="Latest posts"
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix={wordpressWpSettings.blogSlug}
          showAuthor={showAuthor}
        />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!, $slug: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
      }
    }
    wordpressPage(slug: { eq: $slug }) {
      title
      slug
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          id
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          featured_image_url {
            source_url
          }
          count
        }
      }
    }
  }
`
